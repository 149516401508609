import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				KickIt Arena
			</title>
			<meta name={"description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:title"} content={"KickIt Arena"} />
			<meta property={"og:description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link">
				Головна
			</Override>
			<Override slot="link2">
				Послуги
			</Override>
			<Override slot="link3">
				Контакти
			</Override>
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10:33:03.233Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" href="/contacts">
				Контакти
			</Override>
			<Override slot="text1">
				Від оренди полів до проведення заходів - найкращий вибір для любителів футболу
			</Override>
			<Override slot="text">
				Послуги
			</Override>
			<Override slot="text2">
				Наша команда складається з досвідчених гравців та тренерів, які розуміють гру досконально. Вони тут, щоб забезпечити все від моменту бронювання поля до фінального свистка вашої гри. Наша відданість обслуговуванню клієнтів означає, що ми завжди готові допомогти з будь-якими питаннями чи особливими побажаннями. У KickIt Arena ви не просто клієнт – ви частина нашої футбольної родини.
			</Override>
			<Override slot="text3" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Пристрасть до футболу та винятковий сервіс. KickIt Arena була заснована з простою місією: створити першокласний футбольний об'єкт, де гравці будь-якого рівня можуть насолоджуватися улюбленою грою. З самого початку ми прагнули запропонувати найкращі умови для гри, підтримані відмінним обслуговуванням клієнтів.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10:33:03.244Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-1.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z) 0% 0% /cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 2rem 0px" text-align="center" font="--headline2" color="--light">
				Наші основні послуги включають
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1610729866389-fbf90649c302?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Оренда футбольних полів
								</Strong>
								<br />
								<br />
								Гнучкі варіанти бронювання для індивідуальних гравців, команд і організацій.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10:33:03.252Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10%3A33%3A03.252Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10%3A33%3A03.252Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10%3A33%3A03.252Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10%3A33%3A03.252Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10%3A33%3A03.252Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10%3A33%3A03.252Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-4.jpg?v=2024-08-12T10%3A33%3A03.252Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Турніри та ліги
								</Strong>
								<br />
								<br />
								Організовані змагання для гравців різного рівня.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10:33:03.242Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Проведення заходів
								</Strong>
								<br />
								<br />
								Індивідуальні пакети для заходів, таких як корпоративні зустрічі, вечірки та інше.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10:33:03.244Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-2.jpg?v=2024-08-12T10%3A33%3A03.244Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Коучинг та тренування
								</Strong>
								<br />
								<br />
								Професійні тренувальні сесії для підвищення навичок та фізичної підготовки.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10%3A33%3A03.249Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10%3A33%3A03.249Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10%3A33%3A03.249Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10%3A33%3A03.249Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10%3A33%3A03.249Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10%3A33%3A03.249Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10%3A33%3A03.249Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Спортивний магазин
								</Strong>
								<br />
								<br />
								Магазин на місці пропонує футбольне спорядження та аксесуари.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10:33:03.242Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-3.jpg?v=2024-08-12T10%3A33%3A03.242Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Спільнота та підтримка
								</Strong>
								<br />
								<br />
								Ми активно працюємо над створенням яскравої спільноти навколо нашої арени, забезпечуючи можливості для гравців усіх вікових груп та рівнів.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10:33:03.233Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10%3A33%3A03.233Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10%3A33%3A03.233Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10%3A33%3A03.233Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10%3A33%3A03.233Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10%3A33%3A03.233Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10%3A33%3A03.233Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c1e4e5d2770018970971/images/1-2.jpg?v=2024-08-12T10%3A33%3A03.233Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0\n\npx 0px 0px"
				padding="24px 0px 16px 2rem"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				align-content="center"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
					text-transform="uppercase"
				>
					Обирайте KickIt Arena для тренувань, матчів або проведення заходів і приєднуйтесь до нашої спільноти сьогодні. Клікніть на кнопку нижче, щоб ознайомитися з нашими послугами та стати частиною спільноти KickIt Arena вже сьогодні!
				</Text>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});